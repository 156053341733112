import React from "react";
import Layout from "../components/layouts";


const Terms = () => {

  return (
    <Layout>
      <section className="w-100 bg-rich-black pos-r desired-height-3rd">
        <div className="main-wrap mx-auto d-flx flex-dir-col j-c-c h-100 pos-r z-depth-3">
          <div className="wrapper-yt will-grow">
            <div className="maxwidth-mt mx-auto tx-c wrapper will-grow ">
              <h1 className="co-white very-largetext">
                Terms of Use
              </h1>
            </div>
          </div>
        </div>
      </section>

      <section className="w-100">
        <div className="maxwidth-sl mx-auto wrapper-x wrapper-y will-grow">
          this is where the files will go
        </div>
      </section>
    </Layout>
  );
};

export default Terms;
